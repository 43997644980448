
/**
 * Makes a query string from an object for the URL
 * @param {object} query 
 * @returns string
 */

export default function makeQueryString(query) {
    let queryString = "?";

    for( let key in query ){
        if (Array.isArray(query[key])) {

            query[key].forEach(v => {
                queryString += `${key}=${encodeURIComponent(v)}&`;
            });
            
        }else {
            queryString += `${key}=${encodeURIComponent(query[key])}&`;
        }
    }

    return queryString.substring(0, queryString.length - 1);
}
