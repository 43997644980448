

/**
 * Luhn Credit Card Initial Verification
 * @param {Number} num 
 * @returns CC Validation Pass/Fail Boolean
 */

import { removeRejoinerCartItem } from "thirdparty/Rejoiner/Rejoiner.service";

export function luhnCheck(num){
    const arr = (num + '').split('').reverse().map(x => parseInt(x));
    const lastDigit = arr.splice(0, 1)[0];
    let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0);

    sum += lastDigit;

    return sum % 10 === 0;
}



// cart the quantity of products in a cart
export function getCartQnty(cart){
    if( !cart?.line_items ){ return 0; }

    const productQuantities = cart?.line_items?.physical_items.map(item => item?.quantity ?? 0)
    const count = productQuantities.length ? productQuantities.reduce((prev, curr) => prev + curr) : 0;

    return count;
}





/**
 * remove this item
 * @param {string} id
 * @param {string} cartId
 * @param {object} itemMutation
 * @param {number} product_id 
 */

export function removeCartItem({
    id, 
    cartId, 
    itemMutation, 
    product_id
}){
    removeRejoinerCartItem(product_id)

    itemMutation.mutate({ 
        productId: id, 
        cartId, 
        type: "remove", 
        id: product_id 
    }, {
        onSuccess: async data => {
            if( data?.response?.status === 404 ){
                const reactToast = await import("react-toastify");
                reactToast.toast.error(`Error removing this item from cart`)   
            }
        }
    })
}