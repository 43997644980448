import { BigCommerceService } from 'services/bigcommerce/Api.service';


/**
 * Delete a whole cart
 * @param {string} cartId
 * @returns nothing
 */


export default async function DeleteCart(cartId){
    return await BigCommerceService.delete(`/carts/${cartId}`)
        .then(data => data?.data)
        .catch(err => err)
}
