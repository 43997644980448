import { useReducer, createContext } from 'react';
import { CartReducer, InitialState } from "./CartReducer"
import { LocalStorage, storageKeys } from 'services/LocalStorage.service';
import CreateCartAddItem from 'services/bigcommerce/CreateCartAddItem';
import AddCartItem from 'services/bigcommerce/AddCartItem';


// Global Add To Cart
export function AddToCart(body, buyNow = false){
    const cart = LocalStorage.getStorage(storageKeys.cart);
    // const badCartId = "ba4b7de2-52cc-4de0-96ce-2376335f9e6f"
    // const expiredCart = "610ea838-470a-4c51-93a6-7d03f65a8e60"

    return cart ? AddCartItem(body, cart.id, buyNow).then(data => data) : CreateCartAddItem(body, buyNow).catch(err => err);
}



/** --------------------------
 *  Cart Page Context
 *  Create our Provider with our Reducer as our state manager
 ---------------------------- */ 

export const CartContext = createContext();


export const CartContextProvider = props => {
    const [ state, dispatch ] = useReducer(CartReducer, InitialState);

    return (
        <CartContext.Provider value={[state, dispatch]}>
            {props.children}
        </CartContext.Provider>
    );
};

