/**
*   Cart State Manager:
*/

import parseOptionLabel from 'components/applications/pip/helpers/parseOptionLabel.helper';


export const InitialState = {
    items: [],
    coupons: [],
    protectionPlans: [],
    selectedContract: {},
    id: "",
    customerId: "",
    trade: {
        isTradeCustomer: false
    },
    discount: 0,
    multiPackage: {},
    heavyFreight: {},
    shippingCostTotal: 0
};


export const CartReducer = ((state, action) => {
        switch(action.type){

            case "heavyFreight":
                return{
                    ...state,
                    heavyFreight: {
                        ...state.heavyFreight,
                        ...action.data
                    }
                }



            case "multiPackage":
                const newMulti = {
                    ...state.multiPackage,
                }

                if( action?.data !== undefined ){
                    newMulti[action.data] = "brand";
                }

                return{
                    ...state,
                    multiPackage: newMulti
                }


            // at the moment this is only used to display the trade discount amount
            case "updateDiscount": 
                return{
                    ...state,
                    discount: state.discount + action.data
                }


            case "refresh":
            case "init": 

                if( !action?.data ){
                    return {
                        ...state,
                        items: null
                    };
                }

                return {
                    ...state,
                    items: action.data.items,
                    protectionPlans: action.data.items,
                    coupons: action.data.coupons,
                    id: action.data.id,
                    customerId: action.data.customerId
                };


            case "addCartExtras":
                return{
                    ...state,
                    ...action.data
                };
                

            case "removeItem":
                return {
                    ...state,
                    items: state.items.filter(item => item.id !== action.data)
                };


            case "updateItem":
                return{ 
                    ...state,
                    items: state.items.map(item => {
                        let data = action.data;

                        if( item.entityId === action.data.entityId ){
                            let newItem = {...item, ...data};
                            return newItem;
                        }

                        return item;
                    })
                };


            case "updateOption":
                return{
                    ...state,
                    items: state.items.map(item => {
                        let data = action.data;

                        if( item.entityId === data.product_id ){
                            let newItem = {...item};

                            newItem.options.map(option => {
                                if( option.nameId === data.option_id ){

                                    option.valueId = data.option_value_id;
                                    option.value = data.option_label,
                                    option.parsed = parseOptionLabel(data.option_label);

                                    return option;
                                }
                            });

                            return newItem;
                        }

                        return item;
                    })
                };


            case "addCoupon":
                return{
                    ...state,
                    coupons: action.data
                }


            case "removeCoupon":
                return{
                    ...state,
                    coupons: []
                }

s
            default: return state;
        }
});

