import useExportBreadcrumbPath from "custom_hooks/useExportBreadcrumbPath";
import Script from "next/script";

export default function BreadcrumbSchema({ customPath, routerPath }){ 
    const breadcrumbPath = useExportBreadcrumbPath()

    const breadcrumbData = () => {
        const path = breadcrumbPath.buildPath(customPath, routerPath)

        if( path.length ){
            const itemData = path.map((item, index) => {
                if( !item?.breadcrumb ){ return null }

                return {
                    "@type": "ListItem",
                    "position": index + 1,
                    "name": item?.breadcrumb.split("-").join(" ").replace(/\b\w/g, s => s.toUpperCase()),
                    "item": `${process.env.NEXT_PUBLIC_siteUrl}${item?.href}`
                };

            }).filter(Boolean)
        
            const data = {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": itemData
            };

            return {
                __html: JSON.stringify(data)
            };
        }
    }


    return(
        <Script 
            type="application/ld+json" 
            id="breadcrumb"
            dangerouslySetInnerHTML={breadcrumbData()}
            key="item-jsonld-breadcrumb"
        />
    )
}
