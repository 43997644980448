import { LocalStorage, storageKeys } from 'services/LocalStorage.service';
import atcPersistedCart from './atcPersistedCart';


export default class PersistedCart{
    constructor(){
        this.cart = LocalStorage.getStorage(storageKeys.cart, "local")
        this.persistedCart = LocalStorage.getStorage(storageKeys.persistedCart, "local")

        if( !this.persistedCart && this.cart ){
            this.buildPersistedCart()
        }

        this.isCartStale = this.cart ? this.checkLastUpdatedTime(this.cart?.updated_time) : true
    }




    /**
     * ATC the persisted cart items
     * @param {object} itemMutation - atc mutation object from react-query
     * @param {function} onComplete - callback
     */

    refreshCart(
        itemMutation, 
        onComplete = () => null, 
        customerId = 0 
    ){
        atcPersistedCart({
            persistedCart: this.persistedCart,
            itemMutation,
            customerId,
            onComplete: () => onComplete()
        })
    }





    /**
     * Check to see when the cart was last updated
     * @param {UTC String} cartTime 
     */

    checkLastUpdatedTime(cartTime = ""){        
        const givenTime = new Date(cartTime);
        const currentTime = new Date();

        // Get the difference in milliseconds
        const difference = currentTime - givenTime;

        // Convert the difference from milliseconds to hours
        const differenceInHours = difference / 1000 / 60 / 60;
        return differenceInHours > 24

        // const differenceInHours = difference / 1000 / 60;
        // return differenceInHours > 5
    }




    /**
     * Builds the persisted cart object
     * saves to local storage
     */

    buildPersistedCart(){
        this.persistedCart = {
            customer_id: this.cart?.customer_id,
            items: {}
        }

        const physicalItems = this.cart?.line_items?.physical_items

        if( Array.isArray(physicalItems) && physicalItems.length ){
            physicalItems.forEach(item => {
                this.persistedCart.items[item.product_id] = {
                    line_items: {
                        product_id: item.product_id,
                        variant_id: item.variant_id,
                        quantity: item.quantity
                    }
                }

                if( Array.isArray(item.options) && item.options.length ){
                    this.persistedCart.items[item.product_id].line_items.option_selections = item.options.map(opt => {
                        return{
                            name_id: opt.valueId,
                            value_id: opt.valueId,
                            option_value: opt.valueId,
                            option_id: opt.nameId,
                        }
                    })
                }
            })
        }

        LocalStorage.setStorage(storageKeys.persistedCart, this.persistedCart, "local")
    }




    /**
     * Saves the general Big Commerce cart object
     * @param {object} cartResponse 
     */

    saveCart(cartResponse){
        LocalStorage.setStorage(storageKeys.cart, cartResponse, "local")
    }





    /**
     * Deletes individual items from persisted cart
     * @param {object} variables - mutation variables
     * @param {object} cartResponse - Big Commerce cart object
     */

    delete(variables, cartResponse){
        const newPersisted = { ...this.persistedCart }

        // If the type is remove, we remove the item from the cartSelections
        if( Object.hasOwn(newPersisted.items, `${variables?.id}`) ){
            delete newPersisted.items[variables?.id]
        }

        LocalStorage.setStorage(storageKeys.persistedCart, newPersisted, "local")
        this.saveCart(cartResponse)
    }

    



    /**
     * Sets the persistent cart
     * @param {object} variables - mutation variables
     * @param {object} cartResponse - big commerce cart object
     */

    set(variables, cartResponse){
        if( variables.type === "remove"){
            this.delete(variables, cartResponse)
            return
        }


        const cartSelections = { 
            ...this?.persistedCart,
            customer_id: variables?.productBody?.customer_id ?? variables?.customerId,
            items: {
                ...this?.persistedCart?.items
            }
        }
        
        if( Array.isArray( variables?.productBody?.line_items) ){
            variables?.productBody?.line_items.forEach(item => {                        
                cartSelections.items[item?.product_id] = {
                    line_items: item
                }
            })

        }else if( variables?.productBody?.line_item?.product_id ){
            cartSelections.items[variables?.productBody.line_item.product_id] = {
                line_items: {
                    ...cartSelections.items[variables?.productBody.line_item.product_id]?.line_items,
                    quantity: variables?.productBody?.line_item?.quantity
                }
            }
        }
        

        LocalStorage.setStorage(storageKeys.persistedCart, cartSelections, "local")
        this.saveCart(cartResponse)
    }





    /**
     * Removes all carts
     */

    remove(){
        LocalStorage.remove(storageKeys.cart)
        LocalStorage.remove(storageKeys.persistedCart)
    }
}