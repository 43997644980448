import { getCartQnty } from "components/applications/cart/helpers/Cart.helpers";
import GraphCMS from "services/cms/GraphQL.service";
import GetEmailToasterRules from "services/cms/queries/GetEmailToasterRules";
import { setRejoinerCustomerCookie } from "./Rejoiner.helpers";



/**
 * Get the rules setup in the CMS for the email toaster modal
 * @returns esponse object
 */

export async function getCMSRejoinerRules(){
    const cmsGraphQl = new GraphCMS();
    const ruleSchema = GetEmailToasterRules();
    return await cmsGraphQl.post(ruleSchema).then(res => res?.data?.entry?.toasterRules[0])
}



/**
 * Add a customer to a list in Rejoiner
 * @param {object} data 
 * @param {string} list
 * @returns response object
 */

export async function addCustomerToList({ data, list }){
    return await fetch("/api/rejoiner/customerLists?list=" + list, { 
        method: "POST", 
        body: data 
    })
    .then(res => {
        setRejoinerCustomerCookie()

        return res.json()
    })
    .catch(err => {
        console.log(err)
    })
}




/**
 * Set track the product view for Rejoiner
 * @param {object} productData 
 */

export function setRejoinerProduct(productData) {
    try{
        const { product_id, name, price, product_uri, categories, images } = productData;

        _rejoiner.push(['trackProductView', { 
            product_id, 
            name,
            price: Math.round(parseFloat(price)*100),
            image_url: Array.isArray(images) ? images[0]?.image_url : images,
            product_url: process.env.NEXT_PUBLIC_siteUrl +"/"+ product_uri,
            category: categories.map(category => category?.name),
        }]);

    }catch(err){}
}



/**
 * Set a new customer for Rejoiner
 * @param {object} customerData 
 */

export function setRejoinerCustomer(customerData){
    try{
        _rejoiner.push(['setCustomerData', { ...customerData }]);
        _rejoiner.push(['setCustomerEmail', {'email': customerData.email }]);

        setRejoinerCustomerCookie()

    }catch(err){}
}



/**
 * Set the cart data for Rejoiner
 * @param {object} cartData 
 */

export function setRejoinerCheckout(cartData= null, prodCat){
    try{
        const { cart_amount, id, line_items } = (cartData?.data) ? cartData?.data : cartData;

        const newCartData = {
            cart_value: Math.round(parseFloat(cart_amount)*100),
            cart_item_count: getCartQnty(cartData.data),
            promo: null,
            return_url: process.env.NEXT_PUBLIC_siteUrl + `/cart?id=${id}`,
            order_number: id
        }

        _rejoiner.push(['setCartData', newCartData]);


        line_items?.physical_items?.forEach(item => {
            const { product_id, name, image_url, sale_price, quantity, extended_sale_price, url } = item;
            
            (prodCat.hasOwnProperty(product_id)) && _rejoiner.push(['setCartItem', {
                product_id,
                name,
                price: Math.round(parseFloat(sale_price)*100),
                description: null,
                category: Array.isArray(prodCat[product_id]) ? prodCat[product_id].map(category => category?.name) : null,
                item_qty: quantity,
                qty_price:  Math.round(parseFloat(extended_sale_price)*100),
                product_url: process.env.NEXT_PUBLIC_siteUrl +"/"+ url.split("https://secure.authenteak.com/")[1],
                image_url,
                expiration_date: null
            }]);
        })

    }catch(err){}
}



/**
 * remove a removed product from the cart for Rejoiner
 * @param {string} product_id 
 */

export function removeRejoinerCartItem(product_id){
    try{
        _rejoiner.push(['removeCartItem', { product_id }]);

    }catch(err){}
    
}



/**
 * Clear the cart data for Rejoiner
 */

export function clearRejoinerCart(){
    try{
        _rejoiner.push(['clearCartData']);

    }catch(err){}
}