/**
 * Parses an Option's Label String unto a useable object
 * - Temporary until we make swatches objects via microservice
 * @param {string} label - option label
 * @returns object that parses option label meta data
 */

export default function parseOptionLabel(label = ""){
    let data = {},
        additional = [];

    let parts = [label].toString().split('--');

    for (var i in parts) {
        let part = parts[i];

        if (i == 0) {

            let grade = part.match(/Grade ([^ ]+)/i);
            if (grade) {
                data.grade = grade[1]?.toUpperCase().trim();
            }

            let priceAdjust = part.match(/\(([+-]\$[\d.]+)\)/);

            if ( priceAdjust ) {
                data.priceAdjust = priceAdjust[1];
            }


            let priceAdjustNumeric = part.match(/\(([+-])\$([\d.]+)\)/);

            if ( priceAdjustNumeric ) {
                data.priceAdjustNumeric = Math.round(Number.parseFloat(priceAdjustNumeric[1] + priceAdjustNumeric[2].replace(/\,/g,'')) * 100) / 100;

            }else if( !priceAdjustNumeric ){
                data.priceAdjustNumeric = 0
            }


            data.text = part.replace(/Grade [^ ]+ /ig, '').replace(/\([+-][^ ]+/g, '').trim();


            // brand name ~ We're making a bad assumption here but...have too
            let brandName = data.text.split(" ")[0];
            switch (brandName) {
                case "Outdura": data.brandName = brandName; break;
                case "Sunbrella": data.brandName = brandName; break;
                case "Bella": data.brandName = brandName + " Dura"; break;
                case "Acrylic": data.brandName = brandName; break;
                case "O'bravia": data.brandName = "O'bravia"; break;
                case "Spuncrylic": data.brandName = brandName; break;
                case "Suncrylic": data.brandName = brandName; break;
                case "Tempotest": data.brandName = brandName; break;
                case "Bliss": data.brandName = brandName; break;
                case "Symphony": data.brandName = brandName; break;
                case "Revolution": data.brandName = brandName; break;
                case "Docril": data.brandName = brandName; break;
                case "Phifertex": data.brandName = brandName; break;
                case "Textilene": data.brandName = brandName; break;
            }

            // When a brand has more than 1 word

            // Sunbrella Rain Brand
            if( data.text.toLowerCase().includes("sunbrella rain") ){
                data.brandName = "Sunbrella Rain";
            }

            if( data.text.toLowerCase().includes("serge ferrari") ){
                data.brandName = "Serge Ferrari";
            }


            // KB Exclusive
            if ( data.text.toLowerCase().includes("kingsley bate exclusive") ) {
                data.brandName = "Kingsley Bate Exclusive";
            }


            let color = data.text.split(data.brandName);

            if (color) {
                data.color = color.length > 1 ? color[1] : color[0];
                data.color = data.color.indexOf("-") !== -1 ? data.color.split("-")[0]?.trim() : data.color.trim();
            }

            // ships by 
            // let ships = data.text.split("Ships")[1];

            // if (ships) {
            //     data.ships = "Ships " + ships;
            // }
            

        // } else if (part.match(/^LEAD:/)) {

        //     let match = part.match(/^LEAD:(\d+)([W|D])/);

        //     data.leadtime_from = {
        //         value: Number.parseInt(match[1]),
        //         unit: match[2].match(/^d$/i) ? 'day' : 'week'
        //     };

        //     data.leadtime_weeks_from = data.leadtime_from.unit === 'week' ?
        //         data.leadtime_from.value :
        //         data.leadtime_from.value / 5;
        //         match = part.match(/^LEAD:(\d+)([W|D])(?:-(\d+)([W|D])|)$/);

        //     if (match && match[3]) {
        //         data.leadtime_to = {
        //             value: Number.parseInt(match[3]),
        //             unit: match[4].match(/^d$/i) ? 'day' : 'week'
        //         };

        //         data.leadtime_weeks_to = data.leadtime_to.unit === 'week' ?
        //             data.leadtime_to.value :
        //             data.leadtime_to.value / 5;

        //     } else {

        //         data.leadtime_to = data.leadtime_from;
        //         data.leadtime_weeks_to = data.leadtime_weeks_from;
        //     }

        } else {
            additional.push(part);

            // custom filters ~ not really doing this anymore
            // let hasCustomFilter = part.includes("-f");
            // if (hasCustomFilter) {
            //     let customFilter = part.split("-f");
            //     data.customFilter = customFilter[1]?.trim().split(" ");
            // }


            // Kinda Color Code - the actual color code is really just the "longer" number part (e.g. 123456) of xxx-123456, or 00-123456, or xx00-123456, etc patterns. smh.
            data.colorCode = typeof part === "string" ? part.trim() : part;


            // custom filter object
            // if ([label].toString().includes("filter")) {
            //     data.filter = {};

            //     let filterBy = label.split(";filter")[1];

            //     // support for a unique filter object
            //     filterBy.match(/\{(.*?)\}/)[1]?.split(",").forEach((ele) => {
            //         let keyValue = ele.split(":");
            //         data.filter[keyValue[0]] = keyValue[1];
            //     })
            // }

        }
    }

    if (additional.length > 0) {
        data.additional = additional;
    }

    data.raw = label;

    return data;
}
