/**
 * Get Cart 
 * @returns Product Items Array
 * We pluck out the product ids in the cart data and then make our 
 * request to get detailed product data for each item
 * 
 * if we get a 404 we need to remove this cart as it's no longer valid
 */

import axios from "axios";
import { LocalStorage, storageKeys } from "services/LocalStorage.service";


export default async function GetCart(){
    const cart = LocalStorage.getStorage(storageKeys.cart);

    if( cart?.id ){
        return axios.get(`/api/bigcommerce/cart/${cart.id}`)
            .then( async (response) => response?.data?.data)
            .catch(err => {
                try{
                    const responseBody = JSON.parse(err?.response?.data?.responseBody)

                    // this is a true 404 where this cart, with this fetched id does not exist 
                    // this is post checkout when a customer returns to the site
                    if( responseBody?.status === 404 && !responseBody?.title ){
                        LocalStorage.remove(storageKeys.cart)
                        LocalStorage.remove(storageKeys.persistedCart)
                    }

                    return err
                }catch(e){}
            })       
    }
}


