/**
 * Update a given product in cart 
 * as of Aug 2021, via BC API running a PUT for this same operation doesn't work for products
 * that have product options 
 * 
 * @param {Object} body 
 * @param {string} cartId
 * @param {Number} itemId 
 * @returns Updated Cart
 */

import axios from "axios"


export default async function UpdateCartItem(body, itemId, cartId){
    return await axios.delete(`/api/bigcommerce/cart/${cartId}?itemId=${itemId}`)
        .then(async (data) => {
            return await axios.post(`/api/bigcommerce/cart/${cartId}`, body, { timeout: 10000 })
                .then(data => data?.data)
                .catch(err => err)
        })
        .catch(err => err)
}
