/**
 * Update a given product qty in cart
 * @param {Number} itemId 
 * @param {string} cartId
 * @param {Object} body 
 * @returns Updated Cart
 */

import axios from "axios";

export default async function UpdateCartItemQty (itemId, body, cartId){
    return await axios.put(`/api/bigcommerce/cart/${cartId}?itemId=${itemId}`, body, { timeout: 10000 })
        .then(data => data?.data)
        .catch(err => err)
}
