import BigCommerce from 'node-bigcommerce';

export const BigCommerceService = new BigCommerce({
    clientId: process.env.NEXT_PUBLIC_bigCommerce_clientId,
    accessToken: process.env.NEXT_PUBLIC_bigCommerce_accessToken,
    clientSecret: process.env.NEXT_PUBLIC_bigCommerce_clientSecret,
    storeHash: process.env.NEXT_PUBLIC_bigCommerce_storeHash,
    apiVersion: 'v3',
    logLevel: 'info',
    responseType: 'json'
});