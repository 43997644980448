import { LocalStorage, storageKeys } from "services/LocalStorage.service"


/**
 * validates if the current path is in the list of paths to not show the modal 
 * @param {string} path 
 * @param string rules 
 * @returns boolean
 */


export function validatePaths(path="", rules="" ){
    const doNotShow = rules?.split(",").map(item => item.trim()) ?? []

    if( doNotShow.length ){
        return doNotShow.some(item => {
            const newPath = path.split("/").filter(item => item !== "")
            const newItem = item.split("/").filter(item => item !== "")

            return newPath.toString() === newItem.toString()
        })
    }

    return false
}




/**
 * set secure cookie that expires in 5 years to indicate that this rejoiner profile has been created
 * @returns cookie
 */

export function setRejoinerCustomerCookie(){
    const date = new Date();
    date.setTime(date.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));

    const expires = "; expires=" + date.toGMTString();
    document.cookie = `${storageKeys.rejoiner.profile}=true` + expires + "; path=/;secure=true";

    return;
}



/**
 * set cookie with time to watch to reopen modal in 15 days
 * @param {object} rules 
 * @returns cookie
 */

export function setRejoinerDismissedCookie(rules){
    const date = new Date();
    date.setTime(date.getTime() + ((rules?.frequency ?? 15) * 24 * 60 * 60 * 1000));

    const expires = "; expires=" + date.toGMTString();
    document.cookie = `${storageKeys.rejoiner.toaster}=isDismissed` + expires + "; path=/;secure=true";

    return;
}