import Skeleton from 'components/feedback/Skeleton';
import Grid from 'components/layout/Grid';
import GridColumn from 'components/layout/GridColumn';
import utils from 'styles/globals/utils.module.scss';
import Style from "./Breadcrumb.module.scss";

function BreadcrumbLoader({ gridStart = 2, gridEnd = 10 }){
    return(
        <Grid className={Style.block}>
            <GridColumn start={gridStart} end={gridEnd}>
                <div className={Style.preLoaded}>
                    <Skeleton width={"5%"} height={20} className={utils.me_2} />
                    <Skeleton width={"10%"} height={20} className={utils.me_2} />
                    <Skeleton width={"15%"} height={20} />
                </div>
            </GridColumn>
        </Grid>
    )
}


export default BreadcrumbLoader;