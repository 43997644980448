/**
 * updates the logged in customer for the cart
 * @param {Object} body 
 * @param {string} cartId
 * @returns Updated Cart
 */

import axios from "axios"

export default async function UpdateCustomer(cartId, body){
    return await axios.put(`/api/bigcommerce/cart/customer/${cartId}/`, body)
        .then(data => data?.data)
        .catch(err => err)
}

