/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { usePathname, useSelectedLayoutSegment } from "next/navigation.js";


export default function useBreadcrumbStyle(){
    const pathName = usePathname()
    const layoutSegment = useSelectedLayoutSegment()

    const [ isShown, setShouldShow ] = useState(true);
    const [ isLeft, setLeft ] = useState(false);

    useEffect(() => {
        setShouldShow(true)
        setLeft(false)

        if(
            pathName === "/"                || 
            pathName.includes("/account/")  || 
            layoutSegment === "(pip)"       || 
            pathName === "/404/"            ||
            pathName.includes("/help")      || 
            pathName === "/cart/"
        ){
            setShouldShow(false)
        }


        if( layoutSegment === "(blog & guides)" ){
            setLeft(true)
        }

    }, [ pathName, layoutSegment ])


    return{
        isShown,
        isLeft,
        pathName
    }
}