/**
 * Add a message to a trade user's cart
 * @param {string} cartId
 * @returns Updated Cart
 */

import axios from "axios";

export default async function AddTradeUserMessage(
    cartId = "", 
    message = "PLEASE NOTE: Trade Customer Discount Applied."
){
    return axios.post(`/api/bigcommerce/checkout/messages/${cartId}`, { customer_message: message }, { timeout: 10000 })
        .then(response => response?.data?.data)
        .catch(err => err)
}
