"use client"

import PropTypes from "prop-types";
import BreadcrumbSchema from 'components/structured_data/BreadcrumbSchema';
import Grid from 'components/layout/Grid';
import GridColumn from 'components/layout/GridColumn';
import Breadcrumb from './Breadcrumb.tsx';
import clsx from "clsx";
import useBreadcrumbStyle from "components/navigation/Breadcrumbs/useBreadcrumbStyle.js";
import ErrorBoundary from "services/ErrorBoundary.js";
import Style from "./Breadcrumb.module.scss"


function Breadcrumbs({ 
    urlPath, 
    gridStart = 2, 
    gridEnd = 10, 
    className,
    rootClassName,
    listClassName,
    customPlacement = false,
}) {
    const { isShown, isLeft, pathName } = useBreadcrumbStyle()


    return(
        <Grid className={clsx(Style.root, rootClassName, (!isShown && !customPlacement) ? Style.hide : "")}>
            <GridColumn start={gridStart} end={gridEnd}>
                <ErrorBoundary>
                    <Breadcrumb 
                        customPath={urlPath}
                        containerClassName={clsx(Style.block, isLeft && Style.left, className)}
                        listClassName={clsx(Style.list, listClassName)}
                        inactiveItemClassName={Style.item}
                        activeItemClassName={Style.active}
                        rootLabel="Home"
                        linkClassName={Style.link}
                        routerPath={pathName} 
                    />
                    
                    <BreadcrumbSchema 
                        customPath={urlPath} 
                        routerPath={pathName} 
                    />
                </ErrorBoundary>
            </GridColumn>
        </Grid>
    )
}


Breadcrumbs.propTypes = {
    urlPath: PropTypes.string,
    gridEnd: PropTypes.number,
    gridStart: PropTypes.number,
    className: PropTypes.string
}


export default Breadcrumbs;