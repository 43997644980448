"use client"

import React, { useState } from "react";
import Modal from "components/feedback/Modal";
import RenderForm from "../RenderForm";
import Button from "components/forms/Button";
import clsx from "clsx";
import utils from 'styles/globals/utils.module.scss';
import Style from './PageModal.module.scss';
import { GetFreeForm } from 'contexts/FormContext';

function PageModal({
    ctaText,
    marginTop,
    marginBottom,
    formHandle,
    buttonColor,
    buttonType,
    buttonSize,
    buttonClassName,
    buttonTextClassName,
    hideHeadingText = false
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const toggleModal = async () => {
        if (!isModalOpen) {
            setIsLoading(true);
            try {
                const fetchedFormData = await GetFreeForm(formHandle);
                setFormData(fetchedFormData);
            } catch (error) {
                console.error('Error fetching form data:', error);
            }
            setIsLoading(false);
        }
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <Button 
                onClick={toggleModal}
                color={buttonColor}
                type={buttonType}
                size={buttonSize}
                className={clsx(
                    utils.w_100,
                    marginTop && utils[`mt_${marginTop}`],
                    marginBottom && utils[`mb_${marginBottom}`],
                    buttonClassName
                )}
            >
                <small className={buttonTextClassName}>
                    {ctaText}
                </small>
            </Button>
            
            <Modal 
                shouldOpen={isModalOpen} 
                willClose={toggleModal} 
                shouldCloseOnOverlayClick={false}
                hideHeadingText={hideHeadingText}
            >
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    formData && (
                        <>
                            {formData.name && (
                                <h2 className={clsx(utils.fs_2, utils.mt_3, utils.text_center)}>
                                    {formData.name}
                                </h2>
                            )}
                            <RenderForm
                                formControl={formData}
                                onSubmit={toggleModal}
                                hideLegend
                                fullWidthForm
                                formClass={clsx(utils.p_0, utils.mt_4)}
                            />
                        </>
                    )
                )}
            </Modal>
        </>
    );
}

export default PageModal;
